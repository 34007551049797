<template>
  <div v-if="!fetching && formComponent != null">
    <keep-alive>
      <component :defaultData="defaultData" :is="formComponent" />
    </keep-alive>
  </div>
  <div v-else class="spin">
    <a-spin />
  </div>
</template>

<script>
import _ from "lodash";
import { defineAsyncComponent, shallowRef } from "vue";

import { API } from "@/services/api";
import Notification from "@/services/Notification";
export default {
  name: "CampaignDefaultForm",
  computed: {
    adminGameData: function() {
      return this.$store.getters.adminGameData;
    },
    adminThemeData: function() {
      return this.$store.getters.adminThemeData;
    },
    initial: function() {
      return this.$store.getters.initial;
    },
  },
  data() {
    return {
      formComponent: null,
      fetching: true,
      defaultData: {},
    };
  },
  methods: {
    async getDefaultData() {
      try {
        const { data = {} } = await API.getThemeDefaults({
          params: {
            id: this.adminThemeData.encrypt_id,
          },
        });
        const { data: defaultData = {}, status } = data;
        if (status) {
          this.defaultData = defaultData;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    }
  },
  beforeMount() {
    if (!this.$route?.params?.id && (_.isEmpty(this.adminGameData) || _.isEmpty(this.adminThemeData))) {
      this.$router.go(-1);
      return null;
    } else {
      this.formComponent = shallowRef(defineAsyncComponent(() => import(`@/view/${this.initial}/gameLibrary/${this.adminGameData.slug}/${this.adminThemeData.slug}/Form.vue`)));
      this.getDefaultData();
    }
  },
}
</script>